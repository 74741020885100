<template>
  <component
    :is="bannerComponent"
    :href="bannerTo"
    :class="{
      'osk-catalog-menu-mobile-banner': true,
      'osk-catalog-menu-mobile-banner_mini': mini
    }"
    @click="openCatalogDialog(banner.data?.title)"
  >
    <picture v-if="banner.data?.image">
      <source
        :srcset="banner.data?.imageWebp"
        type="image/webp"
      >
      <img
        :src="banner.data?.image"
        alt=""
        class="osk-catalog-menu-mobile-banner__img"
      >
    </picture>

    <div
      v-if="banner.data?.title"
      class="osk-catalog-menu-mobile-banner__title"
    >
      {{ banner.data.title }}
    </div>
  </component>
</template>

<script setup lang="ts">
import {
  BannerElement, DeepLink, BrandsLink, NewLink,
} from '~/restAPI/data-contracts';
import type { TParentCategoryId } from '~/types/main';
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const { parentCategoriesIdForNameMap } = useCategories();

const props = defineProps<{
  banner: BannerElement
  mini?: boolean
  page?: string
}>();

const emits = defineEmits<{
  (e: 'openCatalogDialog', banner: BannerElement): void,
}>();

const bannerLink = computed(() => props.banner?.data?.link);

const bannerComponent = computed(() => {
  const type = bannerLink.value?.type;

  if (type && type !== 'category') return 'a';
  return 'div';
});

const bannerTo = computed(() => {
  const link = bannerLink.value;

  if ((link as DeepLink).deeplink) return genSPALink((link as DeepLink).deeplink);

  if (link?.type === 'brands') {
    const { categoryId } = link as BrandsLink;

    if (categoryId) {
      const category = parentCategoriesIdForNameMap.value[(categoryId as TParentCategoryId)];

      return `/brands/${category}`;
    }
  }

  if (link?.type === 'new') {
    const { categoryId } = link as NewLink;

    if (categoryId) {
      const category = parentCategoriesIdForNameMap.value[(categoryId as TParentCategoryId)];

      return `/catalog/${category}`;
    }
  }

  return undefined;
});

function openCatalogDialog(title?: string) {
  $addEvent(AnalyticsEvents.CatalogMobileLinkClick, {
    page: props.page,
    chapter: props.banner.type,
    id_item: props.banner.blockId,
    item: 'category_2',
  });

  emits('openCatalogDialog', props.banner);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-catalog-menu-mobile-banner {
  position: relative;
  margin-bottom: 12px;
  display: block;
  border-radius: $radius-small;
  overflow: hidden;
  background: $grey-4;
  width: 100%;

  &_mini {
    width: 50%;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    position: absolute;
    left: 12px;
    bottom: 12px;
    white-space: pre-wrap;
  }

  &__img {
    min-height: 130px;
    object-fit: cover;
    width: 100%;
    display: block;
  }
}
</style>
