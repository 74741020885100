<template>
  <div class="osk-menu-bottom-mobile">
    <nuxt-link
      no-prefetch
      to="/"
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': isMain && !showCatalogMenu,
      }"
    >
      <UikitIcon
        name="Main"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      {{ $t('components.business.menuBottomMobile.main') }}
    </nuxt-link>

    <button
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': (showCatalogMenu || isCatalog) && !isNotificationDialog,
      }"
      @click="handleCatalog"
    >
      <UikitIcon
        name="Search"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      {{ $t('components.business.menuBottomMobile.catalog') }}
    </button>

    <span
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': isPublication && !showCatalogMenu,
      }"
      @click.prevent="handlePublication"
    >
      <UikitIcon
        name="Sell"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      {{ $t('components.business.menuBottomMobile.sell') }}
    </span>

    <span
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': isMounted ? (isConciergePage && !showCatalogMenu && !isNotificationDialog) : false,
      }"
      @click.prevent="goToConcierge"
    >
      <UikitIcon
        name="Services"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      {{ $t('components.business.menuBottomMobile.concierge') }}
    </span>

    <span
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': isNotificationDialog,
      }"
      @click="handleNotifications"
    >
      <ClientOnly>
        <span
          v-if=" notificationsCount"
          class="osk-menu-bottom-mobile__item-count"
        >
          {{ notificationsCount > 9 ? '9+' : notificationsCount }}
        </span>
      </ClientOnly>

      <UikitIcon
        name="Notification"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      <span class="osk-menu-bottom-mobile__item-text">{{ $t('components.business.menuBottomMobile.notifications') }}</span>
    </span>

    <span
      :class="{
        'osk-menu-bottom-mobile__item': true,
        'osk-menu-bottom-mobile__item_active': isAccounts && !showCatalogMenu,
      }"
      @click="handleProfile"
    >
      <UikitIcon
        name="Profile"
        size="l"
        class="osk-menu-bottom-mobile__icon"
      />
      {{ $t('common.profile') }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const route = useRoute();
const { isAuth, openAuthDialog } = useAccountStore();
const { count: notificationsCount } = useNotificationsStore();
const { showCatalogMenu, isNotificationDialog } = useHeader();
const { goToConcierge, isConciergePage } = useConcierge();
const { isMounted } = useUiBreakpoints();
const {
  isCatalog,
  isMain,
  isPublication,
  isAccounts,
} = usePageDefinition();

function handleNotifications(e: Event) {
  if (!isAuth.value) {
    openAuthDialog({ chapter: 'notification' });
  } else {
    isNotificationDialog.value = !isNotificationDialog.value;
  }

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'notification',
  });
}

async function handlePublication(e: Event) {
  if (!isAuth.value) {
    openAuthDialog({
      to: '/publication',
      chapter: 'sale_request',
    });
  } else {
    await navigateTo('/publication');
  }

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'sell',
  });
}

async function handleProfile(e: Event) {
  if (!isAuth.value) {
    openAuthDialog({
      to: '/account/products?state=PUBLISHED',
      chapter: 'account',
    });
  } else {
    await navigateTo('/account/products?state=PUBLISHED');
  }

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'profile',
  });
}

async function handleCatalog() {
  showCatalogMenu.value = !showCatalogMenu.value;
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-menu-bottom-mobile {
  @include font-body-all;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 1001;
  padding: 6px 10px 30px;
  border-top: 1px solid #E1E1E1;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: $font-weight-medium;
    font-size: 10px;
    line-height: 12px;
    color: $grey;

    &_active {
      color: $black;

      .osk-menu-bottom-mobile__icon {
        color: $black;
      }
    }
  }

  &__item-count {
    position: absolute;
    right: 50%;
    transform: translateX(100%);
    top: -1px;
    background: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    border-radius: 16px;
  }

  &__icon {
    margin-bottom: 2px;
    color: $grey;
  }

  &__item-text {
    @media screen and (max-width: 400px) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 64px;
    }
  }
}
</style>
