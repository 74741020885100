import { NotificationDTO } from '~/restAPI/data-contracts';
import { NotificationTab } from '~/types/components/tabs';
import { useAccountService } from '~/providers/account';

type TFilteredNotifications = {
  needAction?: NotificationDTO[]
  earlier?: NotificationDTO[]
} | null;

const pageSize = 20;
const cancelNotificationsToken = 'cancelNotificationsToken';
const cancelCommentsToken = 'cancelCommentsToken';

function filterNotifications(items?: NotificationDTO[]): TFilteredNotifications {
  if (!items) return null;

  const earlier: NotificationDTO[] = [];
  const needAction: NotificationDTO[] = [];

  items.forEach((item) => {
    if (item.needAction && !item.actionCompleted) {
      needAction.push(item);
      return;
    }

    earlier.push(item);
  });

  return {
    needAction,
    earlier,
  };
}

function useNotifications() {
  const {
    notificationApi,
    getNoCommentsNotifications,
  } = useAccountService();

  const allNotifications = ref<TFilteredNotifications>(null);
  const currentPageNotifications = ref(1);
  const totalPagesNotifications = ref<number>();

  const {
    pending: notificationsPending,
    execute: getNotifications,
  } = useAsyncData(() => getNoCommentsNotifications({
    page: currentPageNotifications.value, pageSize,
  }, cancelNotificationsToken).then((res) => {
    totalPagesNotifications.value = res.totalPages;

    const items = filterNotifications(res.items);

    if (!allNotifications.value) {
      allNotifications.value = items;
    } else {
      allNotifications.value.needAction?.push(...(items?.needAction ?? []));
      allNotifications.value.earlier?.push(...(items?.earlier ?? []));
    }

    return res;
  }), { immediate: false });

  notificationsPending.value = false;

  function cancelNotifications() {
    notificationApi.abortRequest(cancelNotificationsToken);
  }

  return {
    currentPageNotifications,
    totalPagesNotifications,
    notificationsPending,
    allNotifications,
    getNotifications,
    cancelNotifications,
  };
}

function useComments() {
  const {
    notificationApi,
    getCommentsNotifications,
  } = useAccountService();

  const allComments = ref<TFilteredNotifications>(null);
  const currentPageComments = ref(1);
  const totalPagesComments = ref<number>();

  const {
    pending: commentsPending,
    execute: getComments,
  } = useAsyncData(() => getCommentsNotifications({
    page: currentPageComments.value, pageSize,
  }, cancelCommentsToken).then((res) => {
    totalPagesComments.value = res.totalPages;

    if (!allComments.value) {
      allComments.value = { earlier: res.items };
    } else {
      allComments.value.earlier?.push(...(res.items ?? []));
    }

    return res;
  }), { immediate: false });

  commentsPending.value = false;

  function cancelComments() {
    notificationApi.abortRequest(cancelCommentsToken);
  }

  return {
    currentPageComments,
    totalPagesComments,
    commentsPending,
    allComments,
    getComments,
    cancelComments,
  };
}

export function useNotificationsOrComments() {
  const activeTab = ref(NotificationTab.Notification);

  const {
    currentPageNotifications,
    totalPagesNotifications,
    notificationsPending,
    allNotifications,
    getNotifications,
    cancelNotifications,
  } = useNotifications();

  const {
    currentPageComments,
    totalPagesComments,
    commentsPending,
    allComments,
    getComments,
    cancelComments,
  } = useComments();

  const isNotificationTab = computed(() => activeTab.value === NotificationTab.Notification);
  const isCommentTab = computed(() => activeTab.value === NotificationTab.Comment);

  const loading = computed(() => notificationsPending.value || commentsPending.value);
  const currentPage = computed(() => (isNotificationTab.value
    ? currentPageNotifications.value
    : currentPageComments.value));
  const totalPages = computed(() => (isNotificationTab.value
    ? totalPagesNotifications.value
    : totalPagesComments.value));
  const data = computed(() => (isNotificationTab.value
    ? allNotifications.value
    : allComments.value));
  const isEmpty = computed(() => (!data.value?.earlier?.length && !data.value?.needAction?.length));

  async function fetchData(nextPage?: boolean) {
    if (loading.value) {
      cancelNotifications();
      cancelComments();
    }

    if (isNotificationTab.value) {
      const page = currentPageNotifications.value;

      currentPageNotifications.value = nextPage && page ? page + 1 : 1;
      await getNotifications();

      return;
    }

    if (isCommentTab.value) {
      const page = currentPageComments.value;

      currentPageComments.value = nextPage && page ? page + 1 : 1;
      await getComments();
    }
  }

  function removeData() {
    allNotifications.value = null;
    allComments.value = null;
  }

  return {
    activeTab,
    isNotificationTab,
    isCommentTab,
    loading,
    currentPage,
    totalPages,
    data,
    isEmpty,
    fetchData,
    removeData,
  };
}

export function useNotificationItem(item: NotificationDTO) {
  const now = new Date(Date.now());
  const {
    menCatalogUrl,
    womenCatalogUrl,
  } = useCategories();

  const { t } = useI18n();
  const { account } = useAccountStore();

  function toDateTime(secs: number) {
    const date = new Date(secs * 1000);

    if (date.toLocaleDateString() === now.toLocaleDateString()) {
      if (date.getHours() === now.getHours()) {
        return 'менее часа назад';
      }
      return `${now.getHours() - date.getHours()} ч назад`;
    }
    return `${date.toLocaleDateString('ru', {
      day: '2-digit',
      month: 'long',
    })} в ${date.toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' })}`;
  }

  const typeAvatarOrFirstLetter = [
    'OfferAcceptedNotification',
    'OfferRejectedNotification',
    'NewOfferNotification',
    'NewOffer24hoursNotification',
    'LikePriceDecreasedNotification',
    'CartPriceDecreasedNotification',
    'SubscriptionPriceDecreasedNotification',
    'ProductsPublishedNotification',
    'ProductPublishedNotification',
    'OrderNeedConfirmationNotification',
    'NewCommentNotification',
  ];

  const typeMainIcon = [
    'AddBrandLikeNotification',
    'HowItWorksNotification',
    'RegisterNotification',
    'AddBirthdateAndAvatarNotification',
    'TrustedSetNotification',
  ];

  const typeTitleAndSubtitle = [
    'MyOfferNotification',
    'OfferAcceptedNotification',
    'OfferRejectedNotification',
    'NewOfferNotification',
    'NewOffer24hoursNotification',
    'ProductSentToModerationNotification',
    'ModerationPassedNotification',
    'ProductSentToModerationNotificationAgain',
    'ModerationFailedNotification',
    'ModerationRejectedNotification',
  ];

  const typeNoRightImage = [
    'AddBrandLikeNotification',
    'HowItWorksNotification',
    'AddBirthdateAndAvatarNotification',
    'TrustedSetNotification',
  ];

  const typeSold = [
    'SubscriptionPriceDecreasedNotification',
    'LikePriceDecreasedNotification',
    'CartPriceDecreasedNotification',
    'ProductPublishedNotification',
    'ProductsPublishedNotification',
    'MyOfferNotification',
    'OfferAcceptedNotification',
    'OfferRejectedNotification',
    'ProductSentToModerationNotification',
    'ModerationPassedNotification',
    'ProductSentToModerationNotificationAgain',
  ];

  const typeYouDoIt = [
    'NewOfferNotification',
    'NewOffer24hoursNotification',
    'OrderNeedConfirmationNotification',
    'OrderDeliveredToBuyerNeedAgentReportNotification',
    'AddBirthdateAndAvatarNotification',
    'RegisterNotification',
  ];

  const isSoldOrHidden = item.targetObject?.productState ? [
    'SOLD',
    'HIDDEN',
  ].includes(item.targetObject.productState) : false;

  const newFollowingNotifications = computed(() => item.type === 'NewFollowingNotification');

  const isMainIcon = computed(() => {
    if (item.type) return typeMainIcon.includes(item.type);
    return false;
  });

  const avatarOrFirstLetter = computed(() => (item.type && typeAvatarOrFirstLetter.includes(item.type)) || item.targetObjectType === 'Following');

  const title = computed(() => {
    if (item.type === 'NewCommentNotification') return item.initiator!.nickname!;
    if (item.type && typeTitleAndSubtitle.includes(item.type)) return `${item.title}: ${item.subTitle}`;
    return item.title || '';
  });

  const subTitle = computed(() => {
    if (item.type === 'NewCommentNotification') {
      return `${t('composables.useNotificationsOrComments.NewCommentNotification')}: ${item.shortMessage ?? ''}`;
    }

    if (item.type === 'AnotherCommentNotification') {
      return `${t('composables.useNotificationsOrComments.AnotherCommentNotification')}: ${item.shortMessage ?? ''}`;
    }

    if (item.type === 'ReplyCommentNotification') {
      return `${t('composables.useNotificationsOrComments.ReplyCommentNotification')}: ${item.shortMessage ?? ''}`;
    }

    if (item.targetObjectType === 'Following' && newFollowingNotifications.value) return item.subTitle || '';

    if (item.targetObjectType === 'Publication') {
      if (item.type === 'ModerationRejectedNotification') return t('composables.useNotificationsOrComments.ModerationRejectedNotification');
      if (item.type === 'TrustedSetNotification') return '';
    }
    return item.shortMessage || '';
  });

  const sold = computed(() => {
    if (item.type) {
      return typeSold.includes(item.type) && isSoldOrHidden;
    }
    return false;
  });

  const doIt = computed(() => {
    if (item.type && item.needAction && item.actionCompleted) {
      return typeYouDoIt.includes(item.type);
    }
    return false;
  });

  const date = computed(() => {
    if (sold.value) {
      return t('composables.useNotificationsOrComments.dateSold');
    }
    if (doIt.value) {
      return `${t('composables.useNotificationsOrComments.doIt')} ${toDateTime(Number(item.actionCompletedTime))}`;
    }
    if (item.type === 'NewCommentNotification') return toDateTime(item.targetObject?.publishedAtTime);
    return toDateTime(parseInt(item.createTime!, 10));
  });

  const isNoRightImage = computed<boolean>(() => {
    if (item.type) {
      return !typeNoRightImage.includes(item.type);
    }
    return false;
  });

  const link = computed(() => {
    switch (item.targetObjectType) {
      case 'Offer':
        switch (item.type) {
          case 'MyOfferNotification':
          case 'OfferAcceptedNotification':
          case 'OfferRejectedNotification':
            if (isSoldOrHidden) {
              return '#';
            }
            return '/account/offers';
          case 'NewOfferNotification':
          case 'NewOffer24hoursNotification':
            if (item.needAction && item.actionCompleted) {
              return '#';
            }
            return '/account/offers';
          default:
            return '/account/offers';
        }
      case 'Profile':
        switch (item.type) {
          case 'AddBirthdateAndAvatarNotification':
            if (item.needAction && item.actionCompleted) {
              return '#';
            }
            return '/account';
          case 'AddBrandLikeNotification':
            return '#';
          case 'HowItWorksNotification':
            return '/info/about';
          case 'RegisterNotification':
            return `${account.value.sex === 'MALE' ? menCatalogUrl.value : womenCatalogUrl.value}?oskellyChoice=true`;
          default:
            return item.targetObjectUrl!;
        }
      case 'Publication':
        switch (item.type) {
          case 'ProductSentToModerationNotificationAgain':
          case 'ProductSentToModerationNotification':
            if (isSoldOrHidden) {
              return '#';
            }
            return '/account/products?state=NEED_MODERATION';
          case 'ModerationFailedNotification':
            if (item.needAction && item.actionCompleted) {
              return '/account/products?state=NEED_MODERATION';
            }
            return '/account/sales';
          case 'ModerationPassedNotification':
            if (isSoldOrHidden) {
              return '#';
            }
            return '/account/products?state=PUBLISHED';
          case 'ModerationRejectedNotification':
            return '/account/products?state=REJECTED';
          default:
            return item.targetObjectUrl!;
        }
      case 'Order':
        switch (item.type) {
          case 'OrderHeldNotification':
          case 'OrderConfirmedPartlyNotification':
          case 'OrderDeliveringFromSellerToOfficeNotification':
          case 'OrderDeliveredToExpertiseNotification':
          case 'OrderExpertisePassedPartlyNotification':
          case 'OrderDeliveredToBuyerRequestConfirmationNotification':
          case 'OrderDeliveringFromOfficeToBuyerNotification':
            return '/account/orders';
          case 'LostPaymentNotification':
            return '/cart';
          case 'OrderDeliveredToBuyerNeedAgentReportNotification':
          case 'OrderNeedConfirmationNotification':
            if (item.needAction && item.actionCompleted) {
              return '#';
            }
            return '/account/sales';
          default:
            return item.targetObjectUrl!;
        }
      case 'Following':
        if (newFollowingNotifications.value) {
          return `/profile/${item.initiator!.id}`;
        }
        return item.targetObjectUrl!;
      case 'Product':
        switch (item.type) {
          case 'SubscriptionPriceDecreasedNotification':
          case 'LikePriceDecreasedNotification':
          case 'CartPriceDecreasedNotification':
          case 'ProductPublishedNotification':
          case 'ProductsPublishedNotification':
            if (isSoldOrHidden) {
              return '#';
            }
            return item.targetObjectUrl!;
          default:
            return item.targetObjectUrl!;
        }
      case 'UserBan':
        return '#';
      case 'Like':
        return '/account/sales';
      default:
        return item.targetObjectUrl!;
    }
  });

  const comments = [
    'NewCommentNotification',
    'AnotherCommentNotification',
    'ReplyCommentNotification',
  ];

  const isComment = computed(() => item.type && comments.includes(item.type));

  return {
    isMainIcon,
    avatarOrFirstLetter,
    title,
    subTitle,
    date,
    sold,
    doIt,
    isNoRightImage,
    link,
    newFollowingNotifications,
    isComment,
  };
}
