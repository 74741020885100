<template>
  <UikitForm
    @submit-validate-success="submitSuccess"
  >
    <UikitFormInput
      name="registerNickname"
      :placeholder="$t('components.auth.signUpForm.name')"
      border-bottom
      rules="required|minMaxLength:3,15|oskellyUserName"
      @focus="handleFocusName"
      @blur="handleBlurName"
    />

    <UikitFormFieldCaption
      :message="nameMessage"
      success
    />

    <UikitFormInput
      name="registerEmail"
      :placeholder="$t('components.auth.signUpForm.email')"
      border-bottom
      rules="required|email"
    />

    <UikitFormInput
      name="registerPassword"
      type="password"
      :placeholder="$t('components.auth.signUpForm.password')"
      border-bottom
      rules="required|minMaxLength:6,100|password"
    />

    <i18n-t
      keypath="components.auth.signUpForm.notes.main"
      tag="div"
      class="osk-sign-up-form__text"
    >
      <template #buyerLicense>
        <a
          href="/info/pdf/buyer-license.pdf"
          target="_blank"
          class="osk-sign-up-form__link"
        >{{ $t('components.auth.signUpForm.notes.buyerLicense') }}</a>
      </template>
      <template #agencyAgreement>
        <a
          href="/info/pdf/seller-agency-agreement.pdf"
          target="_blank"
          class="osk-sign-up-form__link"
        >{{ $t('components.auth.signUpForm.notes.agencyAgreement') }}</a>
      </template>
      <template #userAgreement>
        <a
          href="/info/pdf/user-agreement.pdf"
          target="_blank"
          class="osk-sign-up-form__link"
        >{{ $t('components.auth.signUpForm.notes.userAgreement') }}</a>
      </template>
    </i18n-t>

    <UikitFormCheckbox
      v-model="accepted"
      name="subscriptionApprove"
      :label="$t('components.auth.signUpForm.checkbox')"
    />

    <UikitButton
      class="osk-sign-up-form__button"
      full-width
      :loading="loading"
    >
      {{ $t('components.auth.signUpForm.register') }}
    </UikitButton>
  </UikitForm>
</template>

<script setup lang="ts">
import type { TSubmitSuccess } from '~/types/components/form';
import { useAccountService } from '@/providers/account';
import { isCustomHTTPError } from '@/restAPI/error';
import { AnalyticsEvents } from '~/types/analytics';

interface SignupForm {
  registerNickname: string;
  registerEmail: string;
  registerPassword: string;
  subscriptionApprove: boolean;
}

const props = defineProps<{
  page?: string
}>();

const emits = defineEmits<{
  (e: 'success'): void,
}>();

const loading = ref(false);
const accepted = ref(false);
const nameMessage = ref('');

const { register } = useAccountService();
const route = useRoute();
const { getGlobalSettings } = useGlobalSettings(route);
const { $addEvent } = useNuxtApp();
const { t } = useI18n();

async function submitSuccess({ values, actions }: TSubmitSuccess<SignupForm>) {
  loading.value = true;

  try {
    await register({
      ...values,
      registerConfirmPassword: values.registerPassword,
    });

    await getGlobalSettings();

    emits('success');

    $addEvent(AnalyticsEvents.Registration, {
      page: 'profile_reg',
    });
  } catch (error) {
    if (isCustomHTTPError(error) && error.response.data?.errorData) {
      actions.setErrors(error.response.data.errorData);
    }

    loading.value = false;
    console.error(error);
  }
}

function handleFocusName() {
  nameMessage.value = t('components.auth.signUpForm.nameError');
}

function handleBlurName() {
  nameMessage.value = '';
}

watch(() => accepted.value, (value) => {
  $addEvent(AnalyticsEvents.DialogLoginAccepted, {
    page: props.page,
    value: String(value),
  });
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-sign-up-form{
  &__button {
    margin-top: $indent-xlarge;
  }

  &__text {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    color: $grey;
    font-family: $font-family-default;
    display: inline-block;
    margin-top: $indent-compact;
    margin-bottom: $indent-large;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
