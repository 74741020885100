<template>
  <template v-if="FULLTEXT_SEARCH_WEB_ON">
    <div class="osk-base-category-tabs">
      <UikitButton
        v-for="(item) in parentCategoriesSearchTabs"
        :key="item.id"
        :class="[
          'osk-base-category-tabs__tab',
          {
            'osk-base-category-tabs__tab_active': model === item.id,
          }
        ]"
        light-outline
        size="s"
        @click="model = item.id"
      >
        {{ $t(item.name) }}
      </UikitButton>
    </div>
  </template>

  <template v-else>
    <UikitTabs
      v-model="model"
      class="osk-header-search-results-tabs"
      small
    >
      <UikitTabsTab
        v-for="(item) in parentCategoriesSearchTabs"
        :key="item.id"
        :name="item.id as string"
        :title="$t(item.name)"
        class="osk-header-search-results-tabs__tab"
      />
    </UikitTabs>
  </template>
</template>

<script setup lang="ts">
import { TGenderPagesValues } from '~/types/main';

const model = defineModel<TGenderPagesValues>({ required: true });

const { FULLTEXT_SEARCH_WEB_ON } = useExperimentsStore();
const { parentCategoriesSearchTabs } = useCategories();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-base-category-tabs {
  display: flex;
  gap: var(--indent-compact);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: var(--button-height-s);

  &::-webkit-scrollbar {
    display: none;
  }

  &__tab {
    &:hover {
      border-color: var(--grey-3);
    }

    &:active {
      opacity: 0.6;
      border-color: var(--grey-4);
    }

    &_active {
      background: var(--grey-4) !important;
    }
  }
}

.osk-header-search-results-tabs {
  margin-bottom: 1px;
  background-color: var(--white);
  position: relative;
  z-index: 1;

  &__tab {
    @include media-query(lg-and-up) {
      .osk-tab__title {
        padding: var(--indent-compact) var(--indent-mmedium);
      }
    }
  }
}
</style>
