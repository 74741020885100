<template>
  <UikitTabs
    v-model="model"
    class="osk-header-search-suggestions-tabs"
    small
  >
    <UikitTabsTab
      v-for="(item) in tabs"
      :key="item.id"
      :name="item.id"
      :title="$t(item.name)"
      class="osk-header-search-suggestions-tabs__tab"
    />
  </UikitTabs>
</template>

<script setup lang="ts">
import { SuggestionTab, TSuggestionType } from '~/types/components/search';

const model = defineModel<TSuggestionType>({ required: true });

const tabs: SuggestionTab[] = [
  {
    id: 'products',
    name: 'common.products',
  },
  {
    id: 'users',
    name: 'components.header.headerSearchSuggestionsTabs.users',
  },
];
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-suggestions-tabs {
  margin-bottom: 1px;
  background-color: var(--white);
  position: relative;
  z-index: 1;

  &__tab {
    @include media-query(lg-and-up) {
      .osk-tab__title {
        padding: var(--indent-compact) var(--indent-mmedium);
      }
    }
  }
}
</style>
