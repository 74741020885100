<template>
  <UikitIcon
    v-if="!isAuth"
    name="Profile"
    size="l"
    @click="handleOpen"
  />

  <UikitDropdown
    v-else
    v-model="isOpened"
    position="right"
    class="osk-user-menu"
  >
    <template #activator>
      <UikitUserAvatar
        :user="account"
        size="xs"
      />
    </template>

    <UikitMenu
      class="osk-user-menu__list"
    >
      <UikitMenuItem
        v-for="link in userMenu"
        :key="link.url"
        class="osk-user-menu__item"
      >
        <a
          :href="link.url"
          class="osk-user-menu__link"
          @click="handleLink(link)"
        >
          {{ link.text }}
        </a>
      </UikitMenuItem>

      <UikitMenuItem class="osk-user-menu__item">
        <a
          href="/logout"
          class="osk-user-menu__link"
          @click.prevent="handleLogout"
        >
          <UikitLoaderInner
            v-if="logoutLoading"
            small
          />

          {{ $t('components.header.userMenu.logout') }}
        </a>
      </UikitMenuItem>
    </UikitMenu>
  </UikitDropdown>
</template>

<script setup lang="ts">
import { useApi } from '~/restAPI';
import { Logout } from '~/restAPI/Logout';
import { AnalyticsEvents } from '~/types/analytics';

const { t } = useI18n();
const { $addEvent } = useNuxtApp();

const isOpened = ref(false);
const logoutLoading = ref(false);

const { createApiInstance } = useApi();
const logoutApi = createApiInstance(Logout);

const { isAuth, account, openAuthDialog } = useAccountStore();

const { phoneVerifiedStorage } = usePhoneVerification();

const userMenu = [
  { text: t('common.profile'), url: '/account' },
  { text: t('common.products'), url: '/account/products' },
  { text: t('components.header.userMenu.sales'), url: '/account/sales' },
  { text: t('components.header.userMenu.favorites'), url: '/account/favorites' },
  { text: t('components.header.userMenu.orders'), url: '/account/orders' },
];

async function handleLogout() {
  logoutLoading.value = true;

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    action: 'exit',
  });

  const { error } = await useAsyncData(() => logoutApi.postApiV2Logout());

  if (error.value) {
    logoutLoading.value = false;
  } else {
    phoneVerifiedStorage.value = undefined;

    window.location.reload();
  }
}

function handleOpen() {
  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'profile',
  });

  openAuthDialog({ chapter: 'account' });
}

function handleLink(link: { text: string, url: string }) {
  let item = 'profile';

  switch (link.url) {
    case '/account/products':
      item = 'products';
      break;
    case '/account/sales':
      item = 'sales';
      break;
    case '/account/favorites':
      item = 'favorites';
      break;
    case '/account/orders':
      item = 'orders';
      break;
    default:
      break;
  }

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item,
  });
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-user-menu {
  :deep(.osk-dropdown__collapse) {
    border: 0;
    top: calc(100% + 26px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    z-index: 3;
  }

  &__list {
    padding: 0;
    padding-bottom: 8px;
  }

  &__item {
    padding: 0;

    &:last-child {
      position: relative;

      &::before {
        content: '';
        height: 1px;
        width: calc(100% - 32px);
        position: absolute;
        top: 0;
        left: 16px;
        background: $grey-3;
      }
    }
  }

  &__link {
    @include font-style($font-size-body, $line-height-body, $font-weight-medium);
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    width: 282px;
    position: relative;
  }
}
</style>
